const apiPath = {
  // 월드맵
  // PLACE_LIST_AREA: '/api/place/v1/places/area', // 영역 포함 장소 리스트. 아래 MAP_RESOURCE_LIST에 포함 됨.
  MAP_RESOURCE_LIST: '/api/map-resource/v1/map-uid/%s/list',

  // USER_STATUS: '/api/user_status/v1/status', // 유저의 정보 조회. 버전2로 변경
  USER_STATUS: '/api/user-status/v2/map/%s/status',

  // 장소검색 팝업
  // PLACE_SEARCH_LIST: '/api/place/v1/place/search_list', // 장소 검색 리스트
  // PLACE_SEARCH_KEYWORD: '/api/place/v1/place/search', // 키워드로 장소 검색 ?keyword

  PLACE_SEARCH_LIST: '/api/place/v2/map/%s/search-list',
  PLACE_SEARCH_KEYWORD: '/api/place/v2/map/%s/search',

  // 장소상세 팝업
  PLACE_DETAIL:'/api/place/v1/place/%s', // 장소 상세 [ placeId ]
  USER_PLACE_STATUS:'/api/user_status/v1/status/place/%s', // 유저의 장소 상세 [ placeId ]

  // 튜토리얼
  TUTORIAL_DONE:'/api/quest/v1/quest/done-tutorial', // 튜토리얼 완료 저장

  // 퀴즈
  QUIZ_LIST: '/api/quest/v1/quest/%s/quizzes', // 퀴즈 목록 [questId]
  // FAMILY_WALLET_CHECK: '/api/family/v1/check/wallet', // 퀘스트 진행전 지갑상태 체크
  QUEST_STATUS_SAVE: '/api/quest/v1/user/quest/%s', // 퀘스트 진행상태 저장 [ questId ]
  // QUIZ_ANSWER: '/api/quiz/v1/user/quiz', // 퀴즈 정답 확인
  QUIZ_ANSWER: '/api/quiz/v2/solutions', // 퀴즈 정답 확인
  PLACE_CLEAR: '/api/user_status/v1/status/cleared/%s', // 장소완료 팝업 [ placeId ]

  // 데모 기능
  QUEST_STATUS_DELET: '/api/user_status/v1/status/place/%s', // 퀘스트 진행상태 삭제 [ placeId ]

  // [부모] 자녀 학습 현황 보기
  CHILDREN_LEARN_HISTORY: '/api/learn-history/v1/map-uid/%s/children', // 이번 스펙에서 제외.

  SPONSOR_REMAIN_AMOUNT: '/api/sponsor-wallet/v1/mapUid/%s/remain-amount',

  MAP_OPEN_REQUEST: '/api/user-map-mapping/v1/mapUid/%s/open-request',
};

export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};