
import LocalStorageManager from '@/storages/LocalStorageManager';

const state = {
  userData: {},
  role:'',
}

const getters = {
	getUserData: () => {
    state.userData = LocalStorageManager.shared.getUserData()
    return state.userData;
  },
	getUserRole: () => {
    const userData = LocalStorageManager.shared.getUserData();
    if(!userData && !state.userData) return '';
    state.role = userData.role || state.userData.role;
    return state.role;
  },
}

const actions = {
  fetchUserData({ commit }, params) {
    commit('SET_USER_DATA', params)
  },
}

const mutations = {
  SET_USER_DATA(state, payload) {
    state.userData = payload;
    state.role = state.userData.role;
    LocalStorageManager.shared.clearUserData();
    LocalStorageManager.shared.setUserData(payload);
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}