const state = {
  isLocked: false,
};

const getters = {
  isLocked: state => state.isLocked,
};

const actions = {
  fetchLockStart({ commit }, params) {
    commit('START_LOCK_MUTATION', params);
  },
  fetchLockEnd({ commit }, params) {
    commit('END_LOCK_MUTATION', params);
  },
};

const mutations = {
  START_LOCK_MUTATION(state, payload) {
    // const { alertMsg, closeBtnText, compeleteBtnText, isBackEventCompelete, confirmCallBack } = payload;
    state.isLocked = true;
  },
  END_LOCK_MUTATION(state, payload) {
    state.isLocked = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
