import { render, staticRenderFns } from "./AlertPopup.vue?vue&type=template&id=4514a20e&scoped=true&"
import script from "./AlertPopup.vue?vue&type=script&lang=js&"
export * from "./AlertPopup.vue?vue&type=script&lang=js&"
import style0 from "./AlertPopup.vue?vue&type=style&index=0&id=4514a20e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4514a20e",
  null
  
)

export default component.exports