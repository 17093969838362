const state = {
  isLoadedFirst: false,
  isLoadingPage: false,
}

const getters = {
	getIsLoadingPageFirst: (state) => state.isLoadedFirst,
	getIsLoadingPage: (state) => state.isLoadingPage,
}

const actions = {
  fetchLoadingPageFirst({ commit }, params) {
    commit('SET_LOADING_PAGE_FIRST_MUTATION', params);
  },
  fetchLoadingPage({ commit }, params) {
    commit('SET_LOADING_PAGE_MUTATION', params);
  },
}

const mutations = {
  SET_LOADING_PAGE_FIRST_MUTATION(state, payload) {
    state.isLoadedFirst = payload;
  },
  SET_LOADING_PAGE_MUTATION(state, payload) {
    state.isLoadingPage = payload;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}