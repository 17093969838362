<template>
  <div id="app" :class="{short_device : isShortDevice}" :style="{ '--windowH': getWindowHeight? getWindowHeight+'px' : '100%' }">
    <router-view></router-view>
    <!-- <Loading v-if="getIsLoadingPage"/> -->
    <ToastPopup
      v-if="getIsToastPopup"
      :toastType="getIsToastType"
      :toastMsg="getIsToastMsg"
      @onComplete="onCompleteToast" />
    <AlertPopup
      v-if="getIsAlertPopup"
      :alertMsg="getIsAlertMsg"
      :closeBtnText="getIsCloseBtnText"
      :compeleteBtnText="getIsCompeleteBtnText"
      @onClickClose="onCloseAlert"
      @onClickComplete="onCompleteAlert" />
    <!-- <a style="position:fixed;top:100px;left:0;z-index:9999;background:#fff;color:#000;font-size:14px;border:1px solid #000" href="javascript:backKeyEvent()">BACK</a> -->

  </div>
</template>

<script>
import '@/styles/globals.css';

import Loading from '@/components/layout/Loading';
import ToastPopup from '@/components/layout/popup/ToastPopup';
import AlertPopup from '@/components/layout/popup/AlertPopup';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components:{
    Loading,
    ToastPopup,
    AlertPopup,
  },
  mounted(){
    window.backKeyEvent = (count) => {
      if(this.getIsAlertPopup){
        if(this.getIsBackEventCompelete){
          this.onCompleteAlert();
        }else{
          this.onCloseAlert();
        }
      }else{
        this.fetchBackKeyEvent(true);
      }
    }
    window.getTopSafeArea = (safeAreaTopHeight,safeAreaBottomHeight) => {
      this.fetchSafeArea({ safeAreaTopHeight : safeAreaTopHeight, safeAreaBottomHeight : safeAreaBottomHeight });
    }

    window.init = params => {
      const { safeArea } = params;

      this.fetchSafeArea({ safeAreaTopHeight : safeArea.top, safeAreaBottomHeight : safeArea.bottom });
    }

    this.$nextTick(() => {
      this.onResize();
      window.addEventListener('resize', this.onResize);
      this.onScroll();
      window.addEventListener('scroll', this.onScroll);
      // this.onLoadedFirst();
    });
    
    // window.addEventListener('beforeunload', this.unLoadEvent);
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.onResize);

    // window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  beforeDestroy(){
    window.addEventListener('scroll', this.onScroll);
  },
  updated(){
    this.onScroll();
    // this.onLoaded();
  },
  computed:{
    ...mapGetters('commonToast', [ 'getIsToastPopup','getIsToastMsg','getIsToastType' ]),
    ...mapGetters('commonAlert', [ 'getIsAlertPopup','getIsAlertMsg','getIsCloseBtnText','getIsCompeleteBtnText', 'getIsBackEventCompelete', 'getConfirmCallBack' ]),
    ...mapGetters('commonViewport', [ 'getWindowWidth', 'getWindowHeight' ]),
    // ...mapGetters('commonLoading', [ 'getIsLoadingPageFirst','getIsLoadingPage' ]),
    isShortDevice(){
      return this.getWindowHeight <= 640;
    },
  },
  methods:{
    ...mapActions('commonToast', [ 'fetchToastEnd' ]),
    ...mapActions('commonAlert', [ 'fetchAlertEnd' ]),
    ...mapActions('commonViewport', [ 'fetchViewPort', 'fetchScroll', 'fetchSafeArea' ]),
    ...mapActions('commonInterface', [ 'fetchBackKeyEvent' ]),
    onResize(){
      this.fetchViewPort({ width: window.innerWidth, height: window.innerHeight });
    },
    onScroll() {
      this.fetchScroll(window.scrollY);
    },
    onCompleteToast() {
      this.fetchToastEnd();
    },
    onCompleteAlert(){
      this.fetchAlertEnd(true);
      if (this.getConfirmCallBack) {
        this.getConfirmCallBack();
      }
    },
    onCloseAlert(){
      this.fetchAlertEnd(false);
    },
    // unLoadEvent(){
    //   alert(`뒤로가기`);
    // }
  }
}
</script>
<style>
html,body,#app,.inner_app{height:100%;height:var(--windowH)}
</style>