import { selectDataList } from '@/constants/selectDataList';
import { colorDataList } from '@/constants/colorDataList';

export function convertIdToText(value, dataName, cutLength){
  let dataList = [];
  if( Array.isArray(dataName) ){
    dataList = dataName;
  }else{
    dataList = selectDataList[dataName];
  }
  if( !value || value == null || value == 'NONE' || value.length == 0 ){ return '-' }
  // console.log('convertIdToText INIT : ', value, dataName)
  if( !dataList ){
    // console.log('convertIdToText ERROR : dataName를 확인해주세요', value, dataName, dataList);
    return '-'
  }
  if(Array.isArray(value)){
    let valueList = [];
    value.forEach(valueItem => {
      const findItem = dataList.find(item => item.id === valueItem);
      if(!findItem){
        // console.log('convertIdToText ERROR : 해당하는 value 값이 없습니다.', value, dataName, dataList);
        return '-'
      }
      let result = findItem.text;
      if(cutLength){
        result = result.slice(0,cutLength);
      }
      valueList.push(result);
    });
    return valueList.join(', ');
  }else{
    const findItem = dataList.find(item => item.id === value);
    // console.log(findItem)
    if(!findItem){
      // console.log('convertIdToText ERROR : 해당하는 value 값이 없습니다.', value, dataName, dataList);
      return '-'
    }
    let result = findItem.text;
    if(cutLength){
      result = result.slice(0,cutLength);
    }
    return result;
  }
};

export function getSelectDataList(value){
  const dataList = selectDataList[value];
  if( !dataList ){
    // console.log('convertIdToText ERROR : dataName를 확인해주세요', value, dataName, dataList);
    return []
  }
  return dataList
}

export function sortSelectDataList(dataList,dataName){
  const currentSelectDataList = selectDataList[dataName];
  let result = [];
  if( !currentSelectDataList ){
    // console.log('sortSelectDataList ERROR : dataName를 확인해주세요', dataList, dataName, currentSelectDataList);
    return dataList
  }
  currentSelectDataList.forEach(item => {
    const findItem = dataList.find(dataListItem => item.id === dataListItem);
    // console.log(item, findItem)
    if(findItem){
      result.push(findItem);
    }
  })

  return result
}

export function convertIdToColor(value, dataName, prev, anotherClassName){
  const dataList = colorDataList[dataName];
  if( !value || value == null || value == 'NONE' || value.length == 0 ){ return '-' }
  // console.log('convertIdToColor INIT : ', value, dataName)
  if( !dataList ){
    // console.log('convertIdToColor ERROR : dataName를 확인해주세요', value, dataName, dataList);
    return '-'
  }
  const findItem = dataList.find(item => item.id === value);
  // console.log(findItem)
  if(!findItem){
    // console.log('convertIdToColor ERROR : 해당하는 value 값이 없습니다.', value, dataName, dataList);
    return '-'
  }
  let result = findItem.text;
  if(prev){
    result = `${prev}_${result}`;
  }
  if(anotherClassName){
    result = `${result} ${anotherClassName}`;
  }
  return result;
};