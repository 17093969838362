<template>
  <button
    type="button"
    :class="[buttonSizeClassName, buttonStyleClassName]"
    @click.stop="onClickBtn()"><slot/>{{ text }}<slot name="btn_right"/></button>
</template>

<script>
export default {
  name:'Button',
  props:{
    btnSize: String,
    btnStyle: String,
    text: String,
    routeName: String,
    routeParams: Object,
  },
  computed:{
    buttonSizeClassName(){
      return 'btn_'+this.btnSize;
    },
    buttonStyleClassName(){
      return 'btn_'+this.btnStyle;
    }
  },
  methods:{
    onClickBtn(e){
      if( this.routeName ){
        let routeInfo = { name: this.routeName };
        if(this.routeParams){
          routeInfo.params = this.routeParams;
        }
        this.$router.push(routeInfo);
        return;
      }else{
        this.$emit('onClickBtn',e);
      }
    }
  }
}
</script>
<style scoped>
/* xsmall */
/* .btn_xsmall{display:block;width:100%;height:26px;padding:0 14px;border-radius:12px;font-weight:bold;font-size:14px;line-height:18px} */
/* small */
.btn_small{display:block;width:100%;height:48px;padding:0;border-radius:100px;font-weight:700;font-size:20px;line-height:26px}
/* medium */
.btn_medium{display:block;width:100%;height:56px;padding:0 16px;border-radius:8px;font-weight:700;font-size:18px;line-height:28px}
/* large */
/* .btn_large{display:block;width:100%;height:56px;padding:0 16px;border-radius:4px;font-weight:700;font-size:18px;line-height:28px} */

/* primary */
.btn_primary{background-color:#BEA9ED;color:#111}
/* primary_white */
.btn_primary_white{background-color:#8F69DD;color:#fff}

/* secondary */
.btn_secondary{background-color:#232323;color:#fff}
/* secondary_tonal */
.btn_secondary_tonal{background-color:#F3F1F1;color:#232323}

/* tertiary */
.btn_tertiary{background-color:#E23C3C;color:#fff}
/* tertiary_tonal */
.btn_tertiary_tonal{background-color:#F3F1F1;color:#E23C3C}

/* outlined */
.btn_outlined{border:1px solid #232323;color:#232323}
.btn_outlined_shadow{border:1px solid #232323;color:#232323;box-shadow: 4px 4px 0px #232323;}

/* onlytext */
.btn_onlytext{color:#232323}

/* disabled */
button:disabled{background-color:#E7E5E5;color:#A6A4A4;box-shadow:none}
/* icon */
/* button .icon_lemontree{margin:0 6px 2px 0;vertical-align:middle;} */

/* button + button{margin-left:8px} */
.desc_error + button{margin-top:20px}
</style>