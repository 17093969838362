import Vue from 'vue';
import Router from 'vue-router';
import routerPath from './routerPath';
import routerComponent from '@/router/routerComponent';
import LocalStorageManager from '@/storages/LocalStorageManager';
import store from '@/store/index';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: routerPath.HOME,
    },
    {
      path: routerPath.WORLD_MAP,
      // name: 'WorldMap',
      name: 'MapSelector',
      meta: { 
        pageTitle:'WorldMap',
        requiresAuth: false,
        loading: true,
      },
      // component: routerComponent.WorldMap,
      component: routerComponent.MapSelector, // query에 따라 분기.
    },
    {
      path: routerPath.EMPTY,
      name: 'Empty',
      meta: {
        // pageTitle:'WorldMap',
        requiresAuth: false,
        // loading: true,
      },
      component: routerComponent.Empty,
    },
    { path: '*', redirect: routerPath.WORLD_MAP },
  ]
});

router.beforeEach((to, _from, next) => {
  // console.log(to,_from)
  const isToken = LocalStorageManager.shared.getToken();
  const userData = LocalStorageManager.shared.getUserData();
  const toRequiresAuth = to.meta.requiresAuth != false ? true : false;
  const fromRequiresAuth = _from.meta.requiresAuth != false ? true : false;
  const requiresRole = to.meta.requiresRole;
  const requiresUser = to.meta.requiresUser;
  const isLoadingPage = store.getters['commonLoading/getIsLoadingPage'];
  if(to.meta.loading && to.meta.loading === true && isLoadingPage === false){
    store.dispatch('commonLoading/fetchLoadingPage', true);
  }else{
    store.dispatch('commonLoading/fetchLoadingPage', false);
  }
  // 토큰 누락시
  if (!isToken) {
    if (to.path === routerPath.HOME) {
      // store.dispatch('commonToast/fetchToastStart', '로그인 후 이용가능합니다.');
      next(routerPath.WORLD_MAP);
      return;
    }
    if (toRequiresAuth) {
      store.dispatch('commonToast/fetchToastStart', '로그인 후 이용가능합니다.');
      // next({ name: 'LoginDirect' });
      return;
    }
  }
  // 토큰있으면 홈 = 대시보드
  if (isToken && to.path === routerPath.HOME && _from.path != routerPath.WORLD_MAP) {
    // console.log('토큰있으면 홈 = 대시보드')
    next(routerPath.WORLD_MAP);
    return;
  }
  // 로그인 페이지로 이동시 - 권한불필요페이지에서 이동제외
  if( _from.name != null && fromRequiresAuth && to.name != 'LoginDirect' && to.path === routerPath.LOGIN){
    // console.log('로그인 페이지로 이동시')
    const goToNext = () => {
      LocalStorageManager.shared.clearToken();
      LocalStorageManager.shared.clearUserData();
      store.dispatch('commonUserData/fetchUserData', {});
      next();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `로그인 페이지로 이동하면<br>자동으로 로그아웃됩니다.`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: goToNext,
    });
    return;
  }
  // 권한불필요 페이지로 이동시 토큰 클리어
  if(isToken && !toRequiresAuth && fromRequiresAuth){
    // console.log('권한불필요 페이지로 이동시 토큰 클리어')
    LocalStorageManager.shared.clearToken();
    LocalStorageManager.shared.clearUserData();
    store.dispatch('commonUserData/fetchUserData', {});
  }
  // role 제어
  if(isToken && userData && requiresRole){
    const role = userData.role;
    const email = userData.email;
    const isRequiresRole = requiresRole && requiresRole.indexOf(role) < 0;
    const isRequiresUser = requiresUser && requiresUser.indexOf(email) < 0;
    if(isRequiresRole && isRequiresUser){
      store.dispatch('commonToast/fetchToastStart', '조회 권한이 없습니다');
      next(routerPath.WORLD_MAP);
      return;
    }
  }
  // 동일 페이지로 이동시 막기
  // if(to.path === _from.path){
  //   return;
  // }
  next()
})

export default router
