<template>
  <div class="loading_comm">
    <p class="desc_loading">레몬시티로 이동중...</p>
  </div>
</template>
<script>
export default {
  name: 'Loading',
}
</script>

<style scoped>
.loading_comm{position:fixed;top:0;bottom:0;left:0;right:0;z-index:9999;background-color:#1E2428;font-size:0;white-space:nowrap;text-align:center}
.loading_comm:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.desc_loading{display:inline-block;padding-top:120px;font-weight:800;font-size:20px;line-height:22px;color:#fff;vertical-align:middle;white-space:normal;background:url(/assets/images/loading.gif) no-repeat 50% 0;background-size:90px}
</style>