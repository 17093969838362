export function ellipsis ( data, cutoff, strAdd ) {
  var str = data.toString(); // cast numbers

  if( !strAdd ) {
    strAdd = '&#8230;'
  }

  str = str.length < cutoff ? str : str.substr(0, cutoff-1) + strAdd;
  
  return str;
}

export function decodeHTMLEntities( docu, text) {
  var textArea = docu.createElement('textarea');
  textArea.innerHTML = text;
  const value = textArea.value;
  textArea = null;
  return value;
}

export function addComma( value, isDotFormat ) {
  if( value === 0 ) return '0';
  
  if( !value )  return value;

  var strMoney = String( value );
  var isDot = false;
  if( strMoney.indexOf( '.' ) > -1 ){
    var decimal = String( value ).split('.')[1];
    isDot = isDotFormat || Boolean(decimal);
    strMoney = String( value ).split('.')[0];
  }

  if( strMoney.indexOf( ',' ) > -1 ) return strMoney;

  let ret = '';
  let nFromEnd;
  let char;

  for(var i=0; i<strMoney.length; ++i) {
    char = strMoney[ i ];

    nFromEnd = strMoney.length - 1 - i;

    ret += char;
    if( nFromEnd !== 0 && nFromEnd % 3 === 0 && char !== '-' ) {
      ret += ','
    }
  }

  if( isDotFormat ? isDot : decimal ){
    return ret +'.'+ decimal;
  }else{
    return ret
  }
}
export function getMoneyToKorean( money ) {
  if( !money )  return '';

  const strMoneyWithoutComma = String(money).split(',').join('');

  const moneyStringArray = strMoneyWithoutComma.split('');
  const unitArray = ['','십','백','천']; //한단위
  const unitArray2 = ['','만','억','조']; //한묶음단위
  const numberKoreanArray = ['영','일','이','삼','사','오','육','칠','팔','구']; //index 순서에 맞게 한글 배열 만들어둠
  let koreanResult = []; //한글값으로 변환된 배열이 담길 곳
  let result = ''; // 최종결과
  // 숫자 -> 한글값으로 변환
  moneyStringArray.forEach(function (e){
    const numItem = Number(e);
    const koreanItem = numberKoreanArray[numItem];
    koreanResult.push(koreanItem);
  });
  // 한글값으로 변환된 배열 뒤에서부터(작은단위숫자부터) 읽기
  for(var n = 0; n<koreanResult.length; n++){
    const uppendNum = koreanResult.length - n-1;
    const unitIndex = uppendNum%4;
    const unitIndex2 = Math.floor(uppendNum/4);
    let unit = '';
    // 한묶음(만,억,조) 단위내의 값의 유무체크
    if(unitIndex == 0 && (koreanResult.slice(n-3,n+1)).toString() != '영,영,영,영'){
      // 한묶음단위 담기
      unit = unitArray2[unitIndex2];
    }else if(koreanResult[n] != '영'){
      // 한단위 담기
      unit = unitArray[unitIndex];
    }
    // 한단위(십,백,천)의 값이 없는 경우 체크
    // if(koreanResult[n] == '영' || (koreanResult[n] == '일' && n != 0)){
    // ㄴ> 제일 앞에만 '일' 덧붙이고 중간에는 '일'을 덧붙이지 않는 경우에 사용하면 됨
    // ⌐> 현재는 제일 앞, 중간 모두 '일'을 덧붙임
    if(koreanResult[n] == '영'){
      // 단위 담기
      result += unit;
    }else{
      // 숫자값 + 단위 담기
      result += koreanResult[n] + unit;
    }
  }
  return result;
}

export function convertMoneyUtil(value,isUtil){
  if(value === 0 || value === null) return '';
  const stringVal = String(value)
  const slice1 = stringVal.slice(-4);
  const slice2 = stringVal.slice(-8,-4);
  const slice3 = stringVal.slice(-12,-8);
  let result = '';
  if(slice3 && Number(slice3) > 0){
    result += addComma(slice3) + '억';
  }
  if(slice2 && Number(slice2) > 0){
    result += addComma(slice2) + '만';
  }
  if(slice1 && Number(slice1) > 0){
    result += addComma(slice1);
  }
  return result + (isUtil ? '원' : '')
}

export function getMoneyToNumber( money ) {
  if( !money )  return 0;

  const strMoney = String( money );

  const strMoneyWithoutComma = strMoney.split( ',' ).join( '' );

  if( !strMoneyWithoutComma || isNaN( strMoneyWithoutComma ) ) return 0;

  // const ret = Number.parseInt( strMoneyWithoutComma, 10 );
  // const ret = Number(strMoneyWithoutComma);

  return strMoneyWithoutComma;
}

export function make00( nCount ) {
  var ret;

  if( nCount < 10 ) {
    ret = '0' + nCount;
  } else {
    ret = String( nCount );
  }
  return ret;
}

export function isValid( value ) {
  if(value === 0) return true;
  if(value === '0') return true;

  return !!value;
}

export function getCanConvertEngNumber( value ) {
  if( !value )  return false;

  var regType1 = /^[A-Za-z0-9+]*$/;

  return regType1.test(value);

  // if( !value )  return '';

  // const strMoney = String( money ).trim();

  // const strMoneyWithoutComma = strMoney.split( ',' ).join( '' );

  // if( !strMoneyWithoutComma || isNaN( strMoneyWithoutComma ) ) return 0;

  // const ret = Number.parseInt( strMoneyWithoutComma, 10 );

  // return ret;
}

// export function hasSpecialCharacter(str) {
//   const format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

//   if( str.match(format) ){
//     return true;
//   }
  
//   return false;
// }

// export function removeSpecialCharacter(str){  
//   var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi
//   //특수문자 검증
//   if(reg.test(str)){
//     //특수문자 제거후 리턴
//     return str.replace(reg, "");    
//   } else {
//     //특수문자가 없으므로 본래 문자 리턴
//     return str;
//   }  
// }

export function changeOnlyKorEng(str){  
  if (!str) return '';

  const reg = /[^ㄱ-힣a-zA-Z]/gi;

  return str.replace(reg, '');
}

export function getCanConvertHyphenNumber(str){  
  if (!str) return '';

  var regType1 = /^[\d -]+$/;

  return regType1.test(str);
}

export function changeOnlyKorEngSpace(str){  
  if (!str) return '';

  const reg = /[^ㄱ-힣a-zA-Z _]/gi;

  return str.replace(reg, '');
}

export function wordToUpperCase(str, charAtCount){
  if(!str) return;
  if(charAtCount === undefined || charAtCount === null){
    return str.toUpperCase()
  }else{
    return str.charAt(charAtCount-1).toUpperCase() + str.slice(charAtCount)
  }
}

export function wordToLowerCase(str, charAtCount){
  if(!str) return;
  if(charAtCount === undefined || charAtCount === null){
    return str.toLowerCase()
  }else{
    return str.charAt(charAtCount-1).toLowerCase() + str.slice(charAtCount)
  }
}

// example value -> exampleValue
export function wordToCamal(str){
  if(!str || str.indexOf(' ') < 0) return str;
  let strArray = str.split(' ');
  strArray = strArray.map(word => wordToUpperCase(word,1));
  return strArray.join('');
}

export function isEndWithConsonant(korStr) {
  const finalChrCode = korStr.charCodeAt(korStr.length - 1)
  // 0 = 받침 없음, 그 외 = 받침 있음
  const finalConsonantCode = (finalChrCode - 44032) % 28
  return finalConsonantCode !== 0
};

export function endWord(korStr,type) {
  if(!korStr) return;
  // type SUBJECT, OBJECT
  const subjectWordDataList = ['이', '가']; // 주어 조사
  const objectWordDataList = ['을', '를']; // 목적어 조사
  let typeData = subjectWordDataList;
  switch(type){
    case 'SUBJECT' : {
      typeData = subjectWordDataList;
      break;
    }
    case 'OBJECT' : {
      typeData = objectWordDataList;
      break;
    }
  }
  const endWord = isEndWithConsonant(korStr) ? typeData[0] : typeData[1];
  return korStr + endWord;
};

// '\n' -> '<br>'
export function convertLineFeed(value, emptyValue){
  if(!value) return emptyValue || '-';
  return value.replace(/(?:\r\n|\r|\n)/g, '<br />')
}