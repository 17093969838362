// (더미)상태컬러
export const dummy_status = [
  { id:'TODO', text:'default' },
  { id:'DOING', text:'lightgreen' },
  { id:'DONE', text:'blue' },
  { id:'HOLD', text:'orange' },
];

// (더미)상태2
export const dummy_status2 = [
  { id:'High', text:'red' },
  { id:'Normal', text:'green' },
  { id:'Light', text:'blue' },
  { id:'None', text:'default' },
]

export const colorDataList = {
  // ** (더미)상태컬러
  dummy_status : dummy_status,
  dummy_status2 : dummy_status2,
}