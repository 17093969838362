// (더미)상태
export const dummy_status = [
  { id:'TODO', text:'할일' },
  { id:'DOING', text:'진행중' },
  { id:'DONE', text:'완료' },
  { id:'HOLD', text:'보류' },
]

// (더미)상태2
export const dummy_status2 = [
  { id:'High', text:'높음' },
  { id:'Normal', text:'보통' },
  { id:'Light', text:'낮음' },
  { id:'None', text:'없음' },
]

export const selectDataList = {
  // ** (더미)상태
  dummy_status : dummy_status,
  dummy_status2 : dummy_status2,
}