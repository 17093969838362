const state = {
  safeAreaTopHeight: 0,
  safeAreaBottomHeight: 0,
  windowWidth: 0,
  windowHeight: 0,
  scrollTop:0,
  layerInnerWidth:0,
  layerInnerHeight:0,
  layerInnerPosition:{top:0,left:0,bottom:0},
  layerBodyWidth:0,
  layerBodyHeight:0,
  layerBodyPosition:{top:0,left:0,bottom:0},
  layerScrollTop:0,
}

const getters = {
	getSafeAreaTopHeight: (state) => state.safeAreaTopHeight,
	getSafeAreaBottomHeight: (state) => state.safeAreaBottomHeight,
	getWindowWidth: (state) => state.windowWidth,
	getWindowHeight: (state) => state.windowHeight,
	getScrollTop: (state) => state.scrollTop,
	getLayerInnerWidth: (state) => state.layerInnerWidth,
	getLayerInnerHeight: (state) => state.layerInnerHeight,
	getLayerInnerPosition: (state) => state.layerInnerPosition,
	getLayerBodyWidth: (state) => state.layerBodyWidth,
	getLayerBodyHeight: (state) => state.layerBodyHeight,
	getLayerBodyPosition: (state) => state.layerBodyPosition,
	getLayerScrollTop: (state) => state.layerScrollTop,
}

const actions = {
  fetchSafeArea({ commit }, params) {
    commit('SET_SAFE_AREA', params)
  },
  fetchViewPort({ commit }, params) {
    commit('SET_VIEWPOR_MUTATION', params)
  },
  fetchScroll({ commit }, params) {
    commit('SET_SCROLL_MUTATION', params)
  },
  fetchLayerInnerViewPort({ commit }, params) {
    commit('SET_LAYER_INNER_VIEWPOR_MUTATION', params)
  },
  fetchLayerBodyViewPort({ commit }, params) {
    commit('SET_LAYER_BODY_VIEWPOR_MUTATION', params)
  },
  fetchLayerBodyScroll({ commit }, params) {
    commit('SET_LAYER_SCROLL_MUTATION', params)
  },
}

const mutations = {
  SET_SAFE_AREA(state, payload) {
    state.safeAreaTopHeight = payload.safeAreaTopHeight || 0;
    state.safeAreaBottomHeight = payload.safeAreaBottomHeight || 0;
  },
  SET_VIEWPOR_MUTATION(state, payload) {
    state.windowWidth = payload.width;
    state.windowHeight = payload.height;
  },
  SET_SCROLL_MUTATION(state, payload) {
    state.scrollTop = payload;
  },
  SET_LAYER_INNER_VIEWPOR_MUTATION(state, payload) {
    state.layerInnerWidth = payload.width;
    state.layerInnerHeight = payload.height;
    state.layerInnerPosition = payload.position;
  },
  SET_LAYER_BODY_VIEWPOR_MUTATION(state, payload) {
    state.layerBodyWidth = payload.width;
    state.layerBodyHeight = payload.height;
    state.layerBodyPosition = payload.position;
  },
  SET_LAYER_SCROLL_MUTATION(state, payload) {
    state.layerScrollTop = payload;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}