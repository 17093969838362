import Vue from "vue";
import { setEmptyDate } from '@/utils/validateUtils'
import { addComma, wordToUpperCase, wordToLowerCase, wordToCamal, endWord } from '@/utils/stringUtils'

import { convertIdToText, getSelectDataList, convertIdToColor } from "@/utils/selectDataUtils";

// 날짜 포멧
Vue.filter("convertDateFormat", (value, format) => {
  value = setEmptyDate(value);
  if (value) {
      if (!format) {
          format = 'YYYY-MM-DD HH:mm'
      }
      return Vue.moment(value, 'YYYY-MM-DD HH:mm').format(format);
  }
  return '-'
})

// 없는 날짜 설정
Vue.filter("setEmptyDate", (value) => {
  if (value && value != '-') {
    return setEmptyDate(value)
  }
  return '-'
})

// 없는 데이터 설정
Vue.filter("emptyValue", (value,unit ,emptyValueText) => {
  unit = unit ? unit : ''
  if (value && value != '-' && value != '0' && value != 0) {
    return value+unit
  }
  return emptyValueText || '-'
})

// '\n' -> '<br>'
Vue.filter("convertLineFeed", (value, emptyValue) => {
  if(!value) return emptyValue || '-';
  return value.replace(/(?:\r\n|\r|\n)/g, '<br />')
})

// 01012345678 -> 010-1234-5678
Vue.filter("convertPhoneNember", (value) => {
  if(!value) return '-';
  // return value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  return value.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
})

// ex - 5000000 -> 5,000,000
Vue.filter("convertNumberToComma", (value, isDotFormat, emptyValueText) => {
  if(!value) return emptyValueText || '-';
  return addComma(value, isDotFormat);
})

// selectDataList dataList 바인딩용
Vue.filter("getSelectDataList", (value) => {
  return getSelectDataList(value);
})

// selectDataList Value의 text값 바인딩용
Vue.filter("convertIdToText", (value, dataListName, cutLength) => {
  return convertIdToText(value, dataListName, cutLength);
})

// dataList 전체항목 추가
Vue.filter("addDataListAll", (value, id) => {
  const allId = id || null;
  const isHasAll = value.find(item => item.text === '전체');
  let result = [];
  result = result.concat(value)
  if(!isHasAll){
    result.unshift({id:allId, text:'전체'});
  }
  return result;
})

// colortDataList Value의 color값 바인딩용
Vue.filter("convertIdToColor", (value, dataListName, prev, anotherClassName) => {
  return convertIdToColor(value, dataListName, prev, anotherClassName);
})

// 영문 대문자 변경
Vue.filter("wordToUpperCase", (value, charAtCount) => {
  return wordToUpperCase(value, charAtCount);
})

// 영문 소문자 변경
Vue.filter("wordToLowerCase", (value, charAtCount) => {
  return wordToLowerCase(value, charAtCount);
})

// camal 형식으로 변경
Vue.filter("wordToCamal", (value) => {
  return wordToCamal(value);
})

// 마지막 글자 가져오기
Vue.filter("endWord",(korStr, type) => {
  return endWord(korStr, type);
})