import Vue from 'vue'
import App from './App.vue'
import router from './router/';
import routerPath from './router/routerPath';
import poData from './constants/poData.js';
import store from './store/index';
import apiPath from './services/apiPath';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'
import './filters/index';

Vue.config.productionTip = false;
import VueMoment from 'vue-moment';
Vue.use(VueMoment);

// Vue.config.devtools = false;
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
import {Swiper as SwiperClass, Pagination, Navigation, EffectFade} from 'vue-awesome-swiper'
SwiperClass.use([Pagination, Navigation, EffectFade]);


Vue.prototype.$routerPath = routerPath;
Vue.prototype.$apiPath = apiPath;
Vue.prototype.$poData = poData;
Vue.prototype.$store = store;
Vue.prototype.$config = {
  kakaoJSKey : '8b2658deb977e329bfe4c6a2e62d11cc',
}

const userAgent = navigator.userAgent.toLowerCase() || '';
let varUA;
if ( userAgent.indexOf('android') > -1) {
  varUA = 'ANDROID';
}else if ( userAgent.indexOf("iphone") > -1||userAgent.indexOf("ipad") > -1||userAgent.indexOf("ipod") > -1 ) {
  varUA = 'IOS';
}else{
  varUA = 'ETC';
}
Vue.prototype.$varUA = varUA;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')