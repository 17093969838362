<template>
  <div
    :class="['toast_comm', toastType ? `toast_${toastType}`: '' ]"
    ref="toastComm"
    :style="cssProps">
    <p class="desc_toast" v-html="toastMsg"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ToastPopup',
  props:{
    toastType: String,
    toastMsg: String,
  },
  data() {
    return {
      timeoutID0: null,
      timeoutID1: null,
      timeoutID2: null,
    }
  },
  mounted() {
    this.toastMotion();
  },
  beforeUnmount() {
    if (this.timeoutID0) clearTimeout(this.timeoutID0);
    if (this.timeoutID1) clearTimeout(this.timeoutID1);
    if (this.timeoutID2) clearTimeout(this.timeoutID2);
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getSafeAreaTopHeight' ]),
    cssProps(){
      var cssObj = {};
      cssObj = {
        '--activeTop': `${this.getSafeAreaTopHeight + 20}px`,
      }
      return cssObj
    },
  },
  methods:{
    toastMotion(){
      const _this = this;

      this.timeoutID0 = setTimeout(function(){
        _this.$refs.toastComm.classList.add('active');
        this.timeoutID0 = null;
      }, 100);
      
      this.timeoutID1 = setTimeout(function(){
        _this.$refs.toastComm.classList.remove('active');
        this.timeoutID1 = null;
      }, 2100);
      
      this.timeoutID2 = setTimeout(function(){
        _this.$emit("onComplete");
        this.timeoutID2 = null;
      }, 2500);
    },
  }
}
</script>
<style scoped>
.toast_comm{position:fixed;top:0;left:20px;right:20px;z-index:9999;transition:.2s;text-align:center;opacity:0;}
.toast_comm .desc_toast{display:inline-block;width:100%;font-weight:800;font-size:14px;line-height:24px;color:#fff;letter-spacing:-0.5px;padding:24px 16px;border-radius:4px;background-color:#579BF5;text-align:left;vertical-align:top;word-break:break-word;white-space:initial;box-shadow:0px 0px 12px rgba(0, 0, 0, 0.24);box-sizing:border-box}
.toast_comm.toast_error .desc_toast{background-color:#E23C3C}
.toast_comm.active{top:var(--activeTop);opacity:1}
</style>